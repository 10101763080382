import { ref } from "vue";

interface RegisterUser {
    name : string;
    username : string;
    email : string;
    password : string;
    password_confirmation : string;
    role : number | null;
}

export const registerUser = ref< RegisterUser >( {
    name : "",
    username : "",
    email : "",
    password : "",
    password_confirmation : "",
    role : null,
} );

interface Validation {
    name : ({
        message : string;
        required : boolean;
        trigger : string;
    } | {
        min : number;
        max : number;
        message : string;
         trigger : string;
    })[];
    username : ({
        message : string;
        required : boolean;
        trigger : string;
    } | {
        min : number;
        max : number;
        message : string;
         trigger : string;
    })[];
    email : {
        type : string;
        message : string;
        required : boolean;
        trigger : string;
    }[];
    password : ({
        required : boolean;
        message : string;
        trigger : string;
    } | {
        min : number;
        max : number;
        message : string;
        trigger : string;
    })[];
    password_confirmation : ({
        required : boolean;
        message : string;
        trigger : string;

    } | {
        min : number;
        max : number;
        message : string;
        trigger : string;

    } | {
        validator : ( rule : Validation, value : string, callback : any ) => void;
        trigger : string;
    })[];
    role : ( {
        type : 'number',
        required : boolean;
        message : string;

    } | {
        pattern: any;
        message : string;
    })[];
}

const passwordConfirmation = ( rule : Validation, value : string, callback : any ) => {
    if ( value === "" ) {
        callback( new Error( "请再次输入密码" ) );
    } else if ( value !== registerUser.value.password ) {
        callback( new Error( "两次输入密码不一致!") );
    } else {
        callback();
    }
};
//TODO:: password check: required to have at least 1 Cap 1 number and 1 lower

export const registerValidation = ref< Validation >( {
    name : [
        {
            message : "姓名不能为空...",
            required : true,
            trigger : "blur",
        },
        {
            min : 2,
            max : 5,
            message : "长度在2到5个字符",
            trigger : "blur",
        },
    ],
    username : [
        {
            message : "用户名不能为空...",
            required : true,
            trigger : "blur",
        },
        {
            min : 2,
            max : 30,
            message : "长度在2到30个字符",
            trigger : "blur",
        },
    ],
    email : [
        {
            type : "email",
            message : "Email is incorrect...",
            required : true,
            trigger : "blur",
        },
    ],
    password : [
        {
            required : true,
            message : "Password could not be empty...",
            trigger : "blur",
        },
        {
            min : 6,
            max : 30,
            message : "Password's length has to be 6 to 30 characters...",
            trigger : "blur",
        },
    ],
    password_confirmation : [
        {
            required : true,
            message : "Password confirmation could not be empty...",
            trigger : "blur",
        },
        {
            min : 6,
            max : 30,
            message : "Password's length has to be 6 to 30 characters...",
            trigger : "blur",
        },
        { validator : passwordConfirmation, trigger : "blur" },
    ],
    role : [
        {
            type : 'number',
            required : true,
            message : "role could not be empty...",
        },
        {
            pattern: /[2-3]/,
            message : "role could not be registered...",
        },
    ]
} );
