
import { ref, getCurrentInstance } from "vue";
import { registerUser, registerValidation } from "@utils/RegisterValidator.ts";
import { ElMessage } from 'element-plus'



export default {
    name: "Register",
    components: {},
    data(){
        return {
            registerUser : registerUser,
            registerValidation : registerValidation
        }
    },
    setup(){
        // @ts-ignore
        const { proxy } = getCurrentInstance();

        const handleRegister = ( formName: string ) => {
            proxy.$refs[ formName ].validate( async ( valid: boolean ) => {
                if ( valid ) {
                    let registration = {
                        name : registerUser.value.name,
                        username : registerUser.value.username,
                        password : registerUser.value.password,
                        password_confirmation : registerUser.value.password_confirmation,
                        email : registerUser.value.email,
                        role : registerUser.value.role
                    }

                    await proxy.$post( '/register', registration )
                        .then( res => {
                            // redirect to homepage
                            ElMessage( { message: res.message, type: 'success', showClose : true } )
                            proxy.$router.push( '/' )
                        } )
                } else {
                    ElMessage( { message : 'Validation errors', type : 'error', showClose : true } )
                    return false;
                }
            });
        };
        return { handleRegister };
    }
}
